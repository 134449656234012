<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<a-card>
				<a-tabs default-active-key="1" @change="callback">
					<a-tab-pane key="1" tab="安全策略" force-render>
						<!-- 密码复杂性 -->
						<h3>{{ l('PasswordComplexity') }}</h3>
						<a-checkbox v-model="security.useDefaultPasswordComplexitySettings" @change="DefaultSetting">
							{{ l('UseDefaultSettings') }}
						</a-checkbox>
						<br />
						<a-checkbox
							v-model="passwordComplexitySetting.requireDigit"
							:disabled="security.useDefaultPasswordComplexitySettings"
						>
							{{ l('PasswordComplexity_RequireDigit') }}
						</a-checkbox>
						<br />
						<a-checkbox
							v-model="passwordComplexitySetting.requireLowercase"
							:disabled="security.useDefaultPasswordComplexitySettings"
						>
							{{ l('PasswordComplexity_RequireLowercase') }}
						</a-checkbox>
						<br />
						<a-checkbox
							v-model="passwordComplexitySetting.requireNonAlphanumeric"
							:disabled="security.useDefaultPasswordComplexitySettings"
						>
							{{ l('PasswordComplexity_RequireNonAlphanumeric') }}
						</a-checkbox>
						<br />

						<a-checkbox
							v-model="passwordComplexitySetting.requireUppercase"
							:disabled="security.useDefaultPasswordComplexitySettings"
						>
							{{ l('PasswordComplexity_RequireUppercase') }}
						</a-checkbox>
						<br />
						<a-checkbox
							v-model="securitySettings.requireLetter"
							:disabled="security.useDefaultPasswordComplexitySettings"
						>
							{{ l('必须有字母') }}
						</a-checkbox>
						<br />
						<p>{{ l('PasswordComplexity_RequiredLength') }}:</p>
						<a-input-number
							:step="1"
							v-if="!security.useDefaultPasswordComplexitySettings"
							v-model="passwordComplexitySetting.requiredLength"
						/>
						<a-input-number
							:step="1"
							disabled
							v-if="security.useDefaultPasswordComplexitySettings"
							v-model="passwordComplexitySetting.requiredLength"
						/>
						<p></p>
						<a-checkbox v-model="securitySettings.isFirstUpdatePass">
							{{ l('首次登录需要修改密码') }}
						</a-checkbox>
						<br />
						<a-checkbox v-model="securitySettings.isAllowRepeat">
							{{ l('密码不能与当前密码和默认密码一样') }}
						</a-checkbox>
						<br />
						<a-checkbox v-model="securitySettings.isCycleUpdatePass">
							{{ l('周期强制变更密码') }}
						</a-checkbox>
						<br />
						<div v-if="securitySettings.isCycleUpdatePass">
							<p>周期天数:</p>
							<a-input-number :step="1" v-model="securitySettings.cycleUpdatePassDays" :min="0" />
						</div>
						<p></p>

						<div>
							<p>默认密码配置:</p>
							<a-input-password
								placeholder="默认密码"
								v-model="securitySettings.defaultPwd"
								style="width: 150px"
							/>
						</div>
						<p></p>
						<!-- 锁定用户 -->
						<h3>{{ l('UserLockOut') }}</h3>
						<a-checkbox v-model="userLockOut.isEnabled">
							{{ l('EnableUserAccountLockingOnFailedLoginAttemts') }}
						</a-checkbox>
						<p></p>
						<p v-if="userLockOut.isEnabled">{{ l('MaxFailedAccessAttemptsBeforeLockout') }}:</p>
						<a-input-number
							v-if="userLockOut.isEnabled"
							:step="1"
							v-model="userLockOut.maxFailedAccessAttemptsBeforeLockout"
						/>
						<p v-if="userLockOut.isEnabled"></p>
						<p v-if="userLockOut.isEnabled">{{ l('DefaultAccountLockoutDurationAsSeconds') }}:</p>
						<a-input-number
							v-if="userLockOut.isEnabled"
							:step="1"
							v-model="userLockOut.defaultAccountLockoutSeconds"
						/>
						<p><br /></p>
						<p><b>白名单:</b></p>
						<a-row>
							<a-col :span="18">
								<div class="whiteUsers" @click="checkwhiteUsers" v-if="whiteUsersList.length > 0">
									<a-tag
										color="#2db7f5"
										v-for="item in whiteUsersList"
										:key="item.userId"
										style="cursor: pointer;"
									>
										{{ item.realName }}
									</a-tag>
								</div>
								<div class="whiteUsers" @click="checkwhiteUsers" v-else>
									<div style="color: #cccccc">点击添加白名单人员</div>
								</div>
							</a-col>
						</a-row>
						<p><br /></p>
						<a-button :type="'primary'" @click="handleSubmit">
							<a-icon type="save" />
							<span>{{ l('Save') }}</span>
						</a-button>
					</a-tab-pane>
					<a-tab-pane key="2" tab="缓存配置" force-render>
						<p>程序缓存过期时间: <a-input-number :step="1" v-model="tokenExpiration" :min="0" /> 天</p>
						<a-button :type="'primary'" @click="savetokenExpiration()">
							<a-icon type="save" />
							<span>{{ l('Save') }}</span>
						</a-button>
					</a-tab-pane>
				</a-tabs>
			</a-card>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
	AttendancePeopleEditDto,
	PasswordComplexitySetting,
	SecuritySettingsDto,
	SecuritySettingsEditDto,
	SettingWhiteUserListDto,
	TenantSettingsEditDto,
	TenantSettingsServiceProxy,
	UserLockOutSettingsEditDto,
} from '../../../shared/service-proxies';
import moment from 'moment';
import ModalHelper from '../../../shared/helpers/modal/modal-helper';
import SelectUserTag from '@/components/select-user-tag/select-user-tag.vue';

export default {
	name: 'account',
	mixins: [AppComponentBase],
	data() {
		return {
			zh_CN,
			spinning: false,
			//用户所有设置
			tenantSetting: new TenantSettingsEditDto(),
			//安全
			security: new SecuritySettingsEditDto(),
			//密码复杂程度
			passwordComplexitySetting: new PasswordComplexitySetting(),
			//锁定用户
			userLockOut: new UserLockOutSettingsEditDto(),
			//用户安全设置
			securitySettings: new SecuritySettingsDto(),
			//工号规则
			jobNumberRule: {
				isEnableAutoJobNum: true, //是否启用自动工号
				fixedPrefix: '', //前缀
				isEnableCompanyCode: false, //是否启用公司编码
				serialNumber: 5, //序号位数
			},
			//工号规则的提交参数
			fixedRule: [
				{
					itemType: '0',
					formatStr: '是否启用自动工号(忽略)',
					stepValue: '',
					initValue: '',
					description: 'true',
					itemTypeName: '自定义',
				},
				{
					itemType: '0',
					formatStr: '',
					stepValue: '',
					initValue: '',
					description: '',
					itemTypeName: '自定义',
				},
				{
					itemType: '3',
					formatStr: 'code',
					stepValue: '',
					initValue: '',
					description: 'false',
					itemTypeName: '公司',
				},
				{
					itemType: '2',
					formatStr: '000000',
					stepValue: '',
					initValue: '',
					description: '',
					itemTypeName: '流水号',
				},
			],
			tokenExpiration: undefined,
			whiteUsersList: [],
		};
	},
	created() {
		this._tenantSettingServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
	},
	computed: {
		securitysetting() {
			if (
				this.passwordComplexitySetting.requireDigit ||
				this.passwordComplexitySetting.requireLowercase ||
				this.passwordComplexitySetting.requireDigit ||
				this.passwordComplexitySetting.requireNonAlphanumeric ||
				this.passwordComplexitySetting.requireUppercase ||
				this.securitySettings.requireLetter
			) {
				this.security.useDefaultPasswordComplexitySettings = false;
				return true;
			}
		},
	},
	methods: {
		DefaultSetting() {
			if (this.security.useDefaultPasswordComplexitySettings) {
				this.passwordComplexitySetting.requireDigit = false;
				this.passwordComplexitySetting.requireLowercase = false;
				this.passwordComplexitySetting.requireDigit = false;
				this.passwordComplexitySetting.requireNonAlphanumeric = false;
				this.passwordComplexitySetting.requireUppercase = false;
				this.securitySettings.requireLetter = false;
			}
		},
		checkwhiteUsers() {
			let list = [];
			if (this.whiteUsersList.length > 0) {
				list = this.whiteUsersList.map((item) => {
					return { id: item.empId, realName: item.realName };
				});
			}

			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRows: list,
					_selectedRowKeys: _.map(list, 'id'),
				},
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.whiteUsersList = res.map((item) => {
						let entity = new SettingWhiteUserListDto();
						entity.empId = item.id;
						entity.realName = item.realName;
						return entity;
					});
				}
			});
		},
		getData() {
			this.spinning = true;
			this._tenantSettingServiceProxy
				.getAllSettings()
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.security = res.security;
					this.passwordComplexitySetting = res.security.passwordComplexity;
					this.userLockOut = res.security.userLockOut;
					this.securitySettings = res.userSecuritySettings;
					this.whiteUsersList = res.settingWhiteUserList;
					//赋值工号规则
					if (res.jobNumberRule) {
						const jobNumberRule = JSON.parse(res.jobNumberRule);
						this.jobNumberRule.isEnableAutoJobNum = jobNumberRule[0].description !== 'false';
						this.jobNumberRule.fixedPrefix = jobNumberRule[1].formatStr;
						this.jobNumberRule.isEnableCompanyCode = jobNumberRule[2].description !== 'false';
						this.jobNumberRule.serialNumber = jobNumberRule[3].formatStr.length;
					}
				});
		},
		handleSubmit() {
			if (!this.security.useDefaultPasswordComplexitySettings) {
				this.security.passwordComplexity = this.passwordComplexitySetting;
			}
			this.security.userLockOut = this.userLockOut;
			this.tenantSetting.security = this.security;
			this.tenantSetting.userSecuritySettings = this.securitySettings;
			try {
				//赋值工号规则
				//是否启用自动工号
				this.fixedRule[0].description = this.jobNumberRule.isEnableAutoJobNum.toString();
				//固定前缀
				this.fixedRule[1].formatStr = this.jobNumberRule.fixedPrefix;
				//是否启用公司编码
				this.fixedRule[2].description = this.jobNumberRule.isEnableCompanyCode.toString();
				//序号位数
				this.fixedRule[3].formatStr = new Array(this.jobNumberRule.serialNumber)
					.fill(0)
					.toString()
					.replace(RegExp(',', 'g'), '');
				//序列化
				this.tenantSetting.jobNumberRule = JSON.stringify(this.fixedRule);
			} catch (e) {
				abp.message.error('工号规则保存异常' + JSON.stringify(e));
			}
			this.tenantSetting.settingWhiteUserList = this.whiteUsersList;
			this.spinning = true;
			this._tenantSettingServiceProxy
				.updateAllSettings(this.tenantSetting)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification.success({
						description: undefined,
						message: this.l('SavedSuccessfully'),
					});
				});
		},
		/**
		 * 获取缓存配置
		 */
		gettokenExpiration() {
			this.spinning = true;
			this._tenantSettingServiceProxy
				.getTokenExpiration()
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.tokenExpiration = res;
				});
		},
		callback(v) {
			switch (v) {
				case '1':
					this.getData();
					break;
				case '2':
					this.gettokenExpiration();
					break;
			}
		},
		/**
		 * token配置
		 */
		savetokenExpiration() {
			this.spinning = true;
			this._tenantSettingServiceProxy
				.updateTokenExpiration(this.tokenExpiration)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification.success({
						description: undefined,
						message: this.l('SavedSuccessfully'),
					});
				});
		},
	},
};
</script>

<style scoped>
.whiteUsers {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}
.whiteUsers::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.whiteUsers::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.whiteUsers::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}

.ellipsis {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
</style>
